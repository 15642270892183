import React from 'react';
import style from './index.module.scss'


export default function ButtonAppBar(props) {
  const {selectIndex} = props

  return (
    <div className={style['bar-list']}>
      {props.LIST.map((item,i)=>(
          <div
            key={i}
            className={`${style['bar-item']} ${selectIndex == i ? style['select'] : '' }`}
            onClick={() => { props.handleCli(item,i)}}>
            {item.str}</div>
      ))}
    </div>
  )
}