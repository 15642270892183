import React from 'react' 
import Map from '../map'
import {jumpUrl} from '@/util/common'
import style from './index.module.scss'


const Comp5 = () => {
  return (
    <div className={style['comp-wrapper']}>
      <Map/>
      <div className={style['content']}>
        <div className={style['info']}>
          <div className={style['title']}>加入我们</div>
          <div className={style['hit']}>在欧信，我们倡导本分，分享和共赢</div>
          <div className={style['hit1']}>为员工提供高端医疗保障及家庭式度假旅游；鼓励员工积极健身和不断学习进取。</div>
          <div className={style['btn']} onClick={()=>jumpUrl('/joinus')}>JOIN US</div>
        </div>
      </div>
      <div className={style['label-wrapper']}>
        {['办公室','已拓展地区','将拓展地区'].map((item,i)=>(
          <div key={i} className={style['label-item']}>
            <i></i>
            <span>{item}</span>
          </div>
        ))}
      </div>
    </div>
  ) 
}

export default Comp5