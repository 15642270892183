import React from 'react' 
import style from './index.module.scss'

const SHOW_LIST = [
  {
    title: 'OH Credit',
    hit:'为客户提供长周期的分期支付方式，降低客户支付压力',
  },
  {
    title: 'OH Pay',
    hit:'“先享后付”使客户可以更快捷的体验购物全流程，降低消费门槛',
  },
  {
    title: 'OH Cash',
    hit:'为客户提供简单的申请流程、可自由支配的现金额度',
  },
  {
    title: 'OH Card',
    hit:'连接当地金融机构与线上线下消费场景，满足客户线上线下多元化的消费需求',
  },
  {
    title: 'OH AI',
    hit:'为企业提供安全可靠的风控管理服务，为本土金融机构提供贷前、贷中和贷后的全方位风险解决方案',
  },
  {
    title: 'OH Tech',
    hit:'构建开放式的技术平台，为海外中国机构量身定制可扩展和可移植的技术架构支持',
  },
]

const List = () => {
  return (
    <div className={style['list-wrapper']}>
      <div className={style['content']}>
        <div className={style['title']}>欧信国际，助力海外中国企业</div>
        <div className={style['hit']}>通过科技助力海外中国企业在目标国提升经营</div>
        <div className={style['list']}>
          {SHOW_LIST.map((item,i)=>(
            <div className={style['item']}>
              <div className={style['icon']}>
                <i></i>
              </div>
              <div className={style['item-title']}>{item.title}</div>
              <div className={style['item-hit']}>{item.hit}</div>
            </div>
          ))}
        </div>
      </div>

    </div>
  ) 
}

export default List