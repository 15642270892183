import React from 'react' 
import img from '@/assets/img/index_bg4.jpg'
import {jumpUrl} from '@/util/common'
import style from './index.module.scss'


const Comp4 = () => {
  return (
    <div className={style['comp-wrapper']}>
      <img src={img} alt="" />
      <div className={style['content']}>
        <div className={style['title']}>愿景</div>
        <div className={style['hit']}>致力于用科技金融让客户体验更好的生活。为更多跨国中国企业提供一体化金融科技风控支持。帮助人们追逐自己的需求和梦想，创造更多的可能性。</div>
        <div className={style['icon']} onClick={()=>jumpUrl('/about?id=3')}></div>
      </div>
    </div>
  ) 
}

export default Comp4