import React, {useLayoutEffect} from 'react'
import {position,star} from './base64'
import './index.scss'

const runingColor = '#7FCDC9'
const Map = () => {
  useLayoutEffect(()=>{
      // 获取echarts的容器
      var chart = window.echarts.init(document.getElementById("main"));
      var geoCoordMap = {
        印度: [76.81, 24.32],
        印度尼西亚: [112.967808, -0.557969],
      };
      
      var BJData = [
        [
          {
            name: "印度",
            value: 10000,
            dataname: "India",
            position: [10,60]
          },
        ],
        [
          {
            name: "印度尼西亚",
            value: 10000,
            dataname: "Indonesia",
            position: [10,40]
          },
        ],
      ];

      var preRunArr = [
          {
             name: 'Russia',
             showName: '俄罗斯',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#fff',
             positionArr: [101.906925, 62.805929]
          },
          {
             name: 'Pakistan',
             showName: '巴基斯坦',
             offset: [-5, 0], //偏移设置
             position: "left", //显示位置
             color:'#9A9BA6',
             positionArr: [68.823752, 28.961592]
          },
          {
             name: 'Ethiopia',
             showName: '埃塞俄比亚',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#9A9BA6',
             positionArr: [38.92812, 10.02194]
          },
          {
             name: 'Nigeria',
             showName: '尼日利亚',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#9A9BA6',
             positionArr: [8.680634, 10.552903]
          },
          {
             name: 'Kenya',
             showName: '肯尼亚',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#9A9BA6',
             positionArr: [38.005, 1.249885]
          },
          {
             name: 'Philippines',
             showName: '菲律宾',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#9A9BA6',
             positionArr: [126.085499, 10.899424]
          },
          {
             name: 'Mexico',
             showName: '墨西哥',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#9A9BA6',
             positionArr: [-102.811982, 25.331515]
          },
          {
             name: 'Vietnam',
             showName: '越南',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#9A9BA6',
             positionArr: [105.094598, 18.666975]
          },
          {
             name: 'Thailand',
             showName: '泰国',
             offset: [-5, 0], //偏移设置
             position: "left", //显示位置
             color:'#9A9BA6',
             positionArr: [99.587286, 15.892763]
          },
          {
             name: 'Colombia',
             showName: '哥伦比亚',
             offset: [5, 0], //偏移设置
             position: "right", //显示位置
             color:'#9A9BA6',
             positionArr: [-74.627835, 4.731972]
          }
      ]

      // 首都
      var capitalArr = [{
        name: '北京',
        showName: '北京',
        offset: [5, 0], //偏移设置
        position: "left", //显示位置
        color:'#8999A5',
        positionArr: [115.75591149282116, 39.50895823803222]
     },]

    // 办公区域
    var worhkerArr = [{
      name: '广东',
      showName: '深圳',
      offset: [12, -15], //偏移设置
      position: "bottom", //显示位置
      color:'#8999A5',
      positionArr: [116.52550121900015, 28.744872770000143]
   },]

   var worhkerArr1 = [{
    name: '杭州',
    showName: '杭州',
    offset: [15, 0], //偏移设置
    position: "bottom", //显示位置
    color:'#8999A5',
    positionArr: [112.52550121900015, 36.744872770000143]
    },{
      name: '南京',
      showName: '南京',
      offset: [15, 0], //偏移设置
      position: "bottom", //显示位置
      color:'#8999A5',
      positionArr: [121.52550121900015, 36.744872770000143]
    }]

      var preRunData = preRunArr.map((item) => ({
        name: item.name,
        itemStyle: {
          normal: {
            areaColor: "#D2D1D6",
          },
          emphasis: {
              color: runingColor, //悬浮背景
              show: true,
          },
        },
        
      }));

      var runingData = BJData.flat().map((info) => ({
        name: info.dataname,
        itemStyle: {
          normal: {
            areaColor: runingColor,
          },
          emphasis: {
              color: runingColor, //悬浮背景
          },
        },
      }));

      var series = [];
      [[, BJData]].forEach(function (item, i) {
        console.log(item, 'item====')
        series.push({
          type: "effectScatter",
          coordinateSystem: "geo",
          zlevel: 2,
          rippleEffect: {
            //涟漪特效
            period: 2, //动画时间，值越小速度越快
            brushType: "fill", //波纹绘制方式 stroke, fill
            scale: 5,
            //波纹圆环最大限制，值越大波纹越大
          },
          label: {
            normal: {
              show: true,
              position: "bottom", //显示位置
              offset: [30, 60], //偏移设置
              formatter: "{b}", //圆环显示文字
              fontSize:'18',
              color:'#123049'
            },
            emphasis: {
              show: true,
            },
          },
          symbol: "circle",
          symbolSize: function (val) {
            var level = 0;
            var state = Math.floor(val[2] / 5000);
            switch (state) {
              case 0:
                level = 0;
                break;
              case 1:
                level = 1;
                break;
              case 2:
                level = 2;
                break;
              case 3:
                level = 3;
                break;
              case 4:
                level = 4;
                break;
              case 5:
                level = 5;
                break;
              case 6:
                level = 6;
                break;
              case 7:
                level = 7;
                break;
              case 8:
                level = 8;
                break;
              case 9:
                level = 9;
                break;
              default:
                level = 10;
            }
            return 5 + level; //圆环大小
          },
          data: item[1].map(function (dataItem) {
            return {
              name: dataItem[0].name,
              value: geoCoordMap[dataItem[0].name].concat([dataItem[0].value]),
            };
          }),
        });
      });

      preRunArr.forEach(function (item, i) {
        series.push({
          type: "effectScatter",
          coordinateSystem: "geo",
          zlevel: 2,
          rippleEffect: {
            //涟漪特效
            period: 3, //动画时间，值越小速度越快
            brushType: "stroke", //波纹绘制方式 stroke, fill
            scale: 5,
            //波纹圆环最大限制，值越大波纹越大
          },
          symbol: "circle",
          symbolSize: function (val) {
            var level = 0;
            var state = Math.floor(val[2] / 5000);
            switch (state) {
              case 0:
                level = 0;
                break;
              case 1:
                level = 1;
                break;
              case 2:
                level = 2;
                break;
              case 3:
                level = 3;
                break;
              case 4:
                level = 4;
                break;
              case 5:
                level = 5;
                break;
              case 6:
                level = 6;
                break;
              case 7:
                level = 7;
                break;
              case 8:
                level = 8;
                break;
              case 9:
                level = 9;
                break;
              default:
                level = 10;
            }
            return 5 + level; //圆环大小
          },
          label: {
            normal: {
              show: true,
              position: item.position, //显示位置
              offset: item.offset, //偏移设置
              formatter: "{b}", //圆环显示文字
              color:'rgba(18,48,73,0.25)' ,
              fontSize:'14'
            },
            emphasis: {
              show: true,
            },
          },
          data: [
              {
                name: item.showName,
                value: item.positionArr.concat([5000]),  
              }
          ]
        });
      });

      capitalArr.forEach(function (item, i) {
        series.push({
          type: "scatter",
          coordinateSystem: "geo",
          zlevel: 2,
          symbol: star,
          symbolSize: [22, 21],
          label: {
            normal: {
              show: true,
              position: item.position, //显示位置
              offset: item.offset, //偏移设置
              formatter: "{b}", //圆环显示文字
              color:item.color,
              fontSize:'14'
            },
            emphasis: {
              show: false,
            },
          },
          data: [
              {
                name: item.showName,
                value: item.positionArr.concat([0]),  
              }
          ]
        });
      });

      worhkerArr.forEach(function (item, i) {
        series.push({
          type: "scatter",
          coordinateSystem: "geo",
          zlevel: 2,
          symbol: position,
          symbolSize: [31, 39],
          label: {
            normal: {
              show: true,
              position: item.position, //显示位置
              offset: item.offset, //偏移设置
              formatter: "{b}", //圆环显示文字
              color:item.color,
              fontSize:'14'
            },
            emphasis: {
              show: false,
            },
          },
          data: [
              {
                name: item.showName,
                value: item.positionArr.concat([0]),  
              }
          ]
        });
      });

      worhkerArr1.forEach(function (item, i) {
        series.push({
          type: "scatter",
          coordinateSystem: "geo",
          zlevel: 2,
          symbol: null,
          symbolSize: [0.1, 0.1],
          label: {
            normal: {
              show: true,
              position: item.position, //显示位置
              offset: item.offset, //偏移设置
              formatter: "{b}", //圆环显示文字
              color:item.color,
              fontSize:'14'
            },
            emphasis: {
              show: false,
            },
          },
          data: [
              {
                name: item.showName,
                value: item.positionArr.concat([0]),  
              }
          ]
        });
      });

      let option = {
        backgroundColor: "#fff",
        //悬浮提示
        tooltip: {
          trigger: "item",
          backgroundColor: "#1540a1",
          borderColor: "#FFFFCC",
          showDelay: 0,
          hideDelay: 0,
          // enterable: true,
          transitionDuration: 0,
          // extraCssText: "z-index:100",
          formatter: function (params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = "";
            // var name = params.name;
            // var value = params.value[params.seriesIndex + 1];
            // res = "<span style='color:#fff;'>" + name.toString().split(' ')[0]
            //     + "</span><br/>爬虫：" + name.toString().split(' ')[1];
            return res;
          },
        },
        visualMap: {
          //图例值控制
          min: 0,
          max: 10000,
          text: ["High", "Low"],
          show: false,
          calculable: true,
          color: ['#fff'],
        },
        geo: {
          map: "world",
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: false, //是否允许缩放
          layoutCenter: ["50%", "50%"], //地图位置
          layoutSize: "180%",
          itemStyle: {
            normal: {
              color: ["#F0F0F0"], //地图背景色
              borderColor: "#ffffff", //省市边界线
            },
            emphasis: {
              color: "#F0F0F0", //悬浮背景
            },
          },
          regions: [...runingData, ...preRunData],
        },
        series: series,
      };
      chart.setOption(option);
  },[])
  return (
    <div id="main" style={{width: "1231px", height: "700px",position:'absolute',right:'88px',top:'78px',zIndex:9}}></div>
  )
}

export default Map