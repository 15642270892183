import React from 'react' 
import Banner from '@/compontent/Banner'
import List from './components/list'
import Comp4 from './components/comp4'
import Comp5 from './components/comp5'
import style from './index.module.scss'

const Index = () => {
  return (
    <div className={style['page-wrapper']}>
      <Banner />

      <div className={style['info']}>
        <div className={style['info-title']}>欧信国际集团</div>
        <div className={style['info-hit']}>
          <div className={style['info-hit-item']}>
            欧信国际控股集团成立于2021年9月，是一家致力于为海外客户提供一站式金融科技的服务商。 在“一带一路”的影响下，与中国跨国企业协同合作，为多个国家客户提供金融服务产品及生活服务产品。我们为个人客户提供灵活的消费支付方式，推出分期消费品牌OH Credit、先买后付品牌 OH Pay、便捷个人信用品牌OH Cash、数字化全场景和跨生态会员相结合品牌OH Card。为机构客户提供海外科技风控服务，推出智能风控品牌OH AI、开放式科技架构品牌OH Tech。在适应各地市场需求的同时，集中管理我们的核心技术、风险、产品和融资板块。通过科技助力海外中国企业在目标国提升经营能力、增加风控抓手。
          </div>
          <div className={style['info-hit-item']}>
            作为跨国金融科技服务商，欧信国际拥有海外运营经验丰富的团队及多方位的风控措施。欧信国际始终相信，通过我们的科技，可以带给海外的个人客户及机构客户更便捷更高效的生活体验和产品体验。使更多客户不会因为无法一次性支付商店的产品而烦恼。我们也将为商店提高销售额，创建一个多元化、有弹性的生态系统。
          </div>
          <div className={style['info-hit-item']}>
            我们相信，金融科技不仅可以给你的生活带来便捷，也会缩短你和世界的距离。
          </div>
        </div>
      </div>
      <List/>
      <Comp4/>
      <Comp5/>

    </div>
  ) 
}

export default Index