import React, {useState,useEffect} from 'react';
import {jumpUrl} from '@/util/common'
import GuideList from '@/compontent/GuideList'
import style from './index.module.scss'


const LIST = [
  {
    str: '首页',
    url:['/','/index.html',''],
  },
  {
    str: '关于欧信',
    url: ['/about','/about.html']
  },
  {
    str: '业务介绍',
    url: ['/business','/business.html']
  },
  {
    str: '加入我们',
    url: ['/joinus','/joinus.html']
  },
  {
    str: '联系我们',
    url: ['/contact','/contact.html']
  }
]

export default function ButtonAppBar(props) {

  const [selectIndex,setSelectIndex] = useState(0)
  useEffect(()=>{
    console.log(props.location)
    setSelectIndex(LIST.findIndex((item)=>{
      return item.url.includes(props.location.pathname)
    }))
  },[props.location])

  const jumpFun = (item) => {
    jumpUrl(item.url[0])
  }


  return (
    <div className={style['app-bar']}>
      <div className={style['content']}>
      <div className={style['logo']} onClick={()=>jumpUrl('/')}></div> 
      <GuideList LIST={LIST} handleCli={jumpFun} selectIndex={selectIndex}/>
      </div>

    </div>
  );
}