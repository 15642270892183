import React, {useState,useEffect} from 'react';
import banner1 from '@/assets/img/banner1.jpg'
import style from './index.module.scss'


export default function Banner(props) {

  return (
   <div className={style['banner-wrapper']}>
     <img src={banner1} alt="" />
     <div className={style['content-wrapper']}>
       <div className={style['title']}>欧信，让世界更近</div>
       <div className={style['hit']}>OH Credit，Bring the world closer</div>
     </div>
   </div>
  )
}